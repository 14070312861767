import React from 'react'
import T from 'prop-types'
import { Helmet } from 'react-helmet'

function CommonMetatags({ indexed = true }) {
  return (
    <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
      {!indexed && <meta name="robots" content="noindex" />}
      <meta
        name="google-site-verification"
        content="EDSv6rCKWTqwOYFgrObdmDqY2P5fFxvCb4hLsGST2us"
      />
    </Helmet>
  )
}

CommonMetatags.propTypes = {
  indexed: T.bool,
}

export default CommonMetatags
